.navbar-header {
  background-color: #203057;
  // height: 5.4rem;
  border-bottom: 2px solid #56829a;
  // overflow: hidden;
  // background-color: #333;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; 
  z-index: 2;
}


.app-heading{
  color: whitesmoke;
}

.navbar-menu {
  background-color: #203057;
  // border-top: 2px solid #56829A;
  // #69EEFF
}

.data-obs-btn {
  border: 1px solid #69eeff !important;
  color: #ffffff !important;
  margin-left: 1rem;
}

.navbar-menu {
  height: 4.7rem;
}

// .link1 {
//   color: #ffffff !important;
//   font-weight: bold !important;
//   font-size: 1rem !important;
//   text-decoration: none !important;
// }

// .link2 {
//   color: #ffffff !important;
//   font-weight: bold !important;
//   font-size: 1rem !important;
// }

// .link3 {
//   color: #ffffff !important;
//   font-weight: bold !important;
//   font-size: 1rem !important;
// }

// .link4 {
//   color: #ffffff !important;
//   font-weight: bold !important;
//   font-size: 1rem !important;
// }

#drop-1\ basic-nav-dropdown {
  color: #ffffff !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}

.navbar-links {
  color: #ffffff !important;
  font-size: 1rem !important;
  margin-left: 0.3rem;
  text-decoration: none;
}

.links{
  color: #ffffff !important;
    // font-weight: bold !important;
    font-size: 1rem !important;
  text-decoration: none;
  color: whitesmoke;
}

.drop_down{
  color: #ffffff;
  font-size: 1rem;
}

.drop_links{
  color: black;
  text-decoration: none;
  
    display: flex;
    position: relative;
    z-index: 2;
    padding: 2em;
    margin: -2em;

}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: whitesmoke;
}