@media only screen and (max-width: 767px) {
    .main {
        padding-left: 0px !important;
    }
}

.main {
    background-color: #F5F5F5;
}

