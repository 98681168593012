.customToast {
	position: fixed;
	top: 40px;
	right: 20px;
	background-color: #ffffff;
	margin-top: 5rem;

	.toast-header {
		display: flex;
		justify-content: space-between;
		margin: 0 0.625rem;
	}

	.toast-hl
	{
		strong
		{
			margin-left: 10px;
		}
	}
}

.error-msg
{
	color: rgb(251, 31, 31);
	font-size: 0.8rem;
	margin-left: 0.2rem;
}

.accordion-collapse.collapse.show > .accordion-body
{
	overflow-x: scroll;
}